import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import Info from './Info';
import { PackageType } from '../../../types/debit-order.type';
import { SETTINGS } from '../../../constants/settings';

interface InfoProps {
  packageType: PackageType;
  joiningFee: number;
  alreadyMember?: boolean;
  isParent: boolean;
}

export default function InfoMobile({
  packageType,
  joiningFee,
  alreadyMember = false,
  isParent
}: InfoProps) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 'auto', px: 3, pb: 1, pt: 2 }} role="presentation">
      <IconButton
        onClick={toggleDrawer(false)}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <Info
        isParent={isParent}
        totalDependants={packageType.dependants.length}
        joiningFee={joiningFee}
        subName={packageType.subName}
        minDependants={packageType.minDependants}
        subCost={packageType.subCost}
        alreadyMember={alreadyMember}
      />
    </Box>
  );

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        endIcon={<ExpandMoreRoundedIcon />}
        onClick={toggleDrawer(true)}
        sx={{ width: '100%', justifyContent: 'space-between' }}
      >
        Review Order
      </Button>
      <Drawer open={open} anchor="top" onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}
