import * as React from 'react';
import { Container } from '@mui/material';

type IProps = {};

const Dashboard: React.FC<IProps> = () => {
  return <Container maxWidth="md">test</Container>;
};

export default Dashboard;
