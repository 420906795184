import * as React from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Review() {
  return (
    <Stack spacing={2}>
      <Divider>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
      </Divider>

      <Typography gutterBottom>
        I hereby apply for membership at Elite Muay Thai. Monthly membership
        fees (as stated below) shall be paid by me in advance, before the 1st of
        each month. Membership is paid on a monthly basis.
      </Typography>

      <Typography gutterBottom>
        I acknowledge that membership entitles me to attend any or all classes
        to receive tuition and practice in the art of Muay Thai, High-Intensity
        Training, and Fitness. I further agree that my absence from attending
        such classes will not entitle me to a refund or suspension of payment of
        my membership fee or any part thereof. If private classes in fitness
        and/or self-defense are required from Elite Muay Thai, these will be
        arranged and paid for separately on an ad-hoc basis.
      </Typography>

      <Typography gutterBottom>
        Elite Muay Thai is sanctioned by the World Muaythai Organization (WMO)
        for Muay Thai training and competition, and by WKU South Africa for K1
        training and competition. I will not maliciously or willfully use my
        knowledge to unlawfully injure another and shall abide by the rules of
        Muay Thai and K1 as laid down, conducting myself honorably at all times.
      </Typography>

      <Typography gutterBottom>
        I further indemnify and hold harmless, Elite Muay Thai, in respect of
        any personal loss, damage, theft, or injury suffered by me while
        attending classes. I also indemnify Elite Muay Thai for any damages
        stemming from negligence of any nature that I may suffer.
      </Typography>

      <Typography gutterBottom>
        I accept that if I breach any of the above terms, I may be suspended or
        expelled from classes and, in such an event, shall forfeit any
        membership fees already paid by me.
      </Typography>

      <Typography gutterBottom>
        Cancellation Policy:
        <br />I understand that should I wish to cancel my membership, I am
        required to give 30 days’ written notice. This notice must be provided
        in writing (email or letter) and submitted to Elite Muay Thai. I
        acknowledge that failure to provide this notice will result in continued
        billing for the next month, as no cancellations will be processed
        without a proper 30-day notice period.
      </Typography>

      <Typography gutterBottom>
        I understand that no classes are conducted on public holidays and that
        there is a recess during Christmas, during which time no classes are
        held. Full fees are still payable for the month of December.
      </Typography>

      <Typography gutterBottom>
        I voluntarily accept the risk of injury during my training, whether on
        or off Elite Muay Thai’s premises, and agree that neither the
        principals, instructors, nor anyone involved in an incident may be held
        responsible for any injury whatsoever.
      </Typography>

      <Typography gutterBottom>
        I consent to the irrevocable and unrestricted use of any photos, videos,
        or other media taken of me during events, activities, and related
        functions for marketing, promotional, and social media purposes. This
        includes, but is not limited to, publication on Elite Muay Thai’s
        website, social media channels, promotional materials, and any other
        collateral, without any further compensation or notification.
      </Typography>

      <Typography gutterBottom>
        I confirm that this application shall become effective and binding on me
        immediately upon acceptance by Elite Muay Thai or its duly authorized
        agent. Signature by him/her on this form shall constitute acceptance of
        my application and dispense with the need for express or formal notice
        of acceptance.
      </Typography>
    </Stack>
  );
}
