import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DebitOrder } from '../../../types/debit-order.type';
import axios from 'axios';
import { BankAccountType } from '../../../types/bank-account.type';
import { useEffect, useState } from 'react';

export const FormGrid = styled(Grid)(() => ({
	display: 'flex',
	flexDirection: 'column',
}));

type PaymentFormProps = {
	setData: (data: DebitOrder) => void;
	saveJoiningFee: (fee: number, already_a_member: boolean) => void;
};

// TODO: debit_order_day field

export default function PaymentForm({
	setData,
	saveJoiningFee,
	...props
}: PaymentFormProps) {
	const [bank_account_holder, setBankAccountHolder] = useState<string>('');
	const [account_number, setAccountNumber] = useState<string>('');
	const [branch_code, setBranchCode] = useState<string>('');
	const [bank_name, setBankName] = useState<string>('');
	const [joining_fee, setJoiningFee] = useState<number>(0);
	const [signed_at, setSignedAt] = useState<string>('');
	const [bank_account_type, setBankAccountType] = useState<number>(1);
	const [debit_order_day, setDebitOrderDay] = useState<number>(26);
	const [already_a_member, setAlreadyMember] = useState<boolean>(false);
	const [doAllowedDays, setDOAllowedDays] = useState<number[]>([21, 26, 31]);
	const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>(
		[]
	);

	useEffect(() => {
		async function getData() {
			const { data: bank } = await axios.get('bank-account-types');
			const { data: joiningFee } = await axios.get('settings/joining-fee');

			setBankAccountTypes(bank);
			setJoiningFee(joiningFee.joining_fee);
			saveJoiningFee(joiningFee.joining_fee, already_a_member);
		}

		getData();
	}, [already_a_member]);

	useEffect(() => {
		setData({
			bank_account_holder,
			account_number,
			signed_at,
			branch_code,
			bank_name,
			bank_account_type,
			debit_order_day,
			already_a_member,
		});
	}, [
		bank_account_holder,
		account_number,
		signed_at,
		branch_code,
		bank_name,
		bank_account_type,
		debit_order_day,
		already_a_member,
	]);

	return (
		<Stack spacing={{ xs: 3, sm: 6 }} useFlexGap>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Grid container spacing={3}>
					<FormGrid size={{ xs: 12 }}>
						<Divider>
							<Typography variant="h4">Debit Order Details</Typography>
						</Divider>
						<Typography align="center" marginTop={2} marginBottom={2}>
							A joining fee of R{joining_fee} will be added to the first debit
							amount.
							<br />
							If you are already a member but new to the online platform,
							<br />
							check the checkbox below:
						</Typography>
						<FormControlLabel
							sx={{ alignSelf: 'center' }}
							control={
								<Checkbox
									// value={already_a_member}
									onChange={(e) => setAlreadyMember(!already_a_member)}
								/>
							}
							label="I am already a member at Elite Muaythai"
						/>
					</FormGrid>
					<FormGrid size={{ xs: 12 }}>
						<Divider textAlign="left">
							<Typography variant="h5">Bank Account Details</Typography>
						</Divider>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 6 }}>
						<FormLabel htmlFor="bank_account_holder" required>
							Name of account holder
						</FormLabel>
						<OutlinedInput
							id="bank_account_holder"
							autoComplete="name"
							placeholder="John Doe"
							required
							size="small"
							onChange={(e) => setBankAccountHolder(String(e.target.value))}
						/>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 6 }}>
						<FormLabel htmlFor="account_number" required>
							Account number
						</FormLabel>
						<OutlinedInput
							id="account_number"
							placeholder="0000 0000 0000 0000"
							required
							size="small"
							onChange={(e) => setAccountNumber(String(e.target.value))}
						/>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 4 }}>
						<FormLabel htmlFor="bank_name" required>
							Bank Name
						</FormLabel>
						<OutlinedInput
							id="bank_name"
							placeholder="FNB"
							required
							size="small"
							onChange={(e) => setBankName(e.target.value)}
						/>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 3 }}>
						<FormLabel htmlFor="branch_code" required>
							Branch Code
						</FormLabel>
						<OutlinedInput
							id="branch_code"
							placeholder="1234"
							required
							size="small"
							onChange={(e) => setBranchCode(e.target.value)}
						/>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 5 }}>
						<FormControl>
							<FormLabel htmlFor="debit_order_day" required>
								Debit Order Day
							</FormLabel>
							<RadioGroup
								row
								defaultValue={debit_order_day}
								name="debit_order_day"
								onChange={(e) => setDebitOrderDay(Number(e.target.value))}
							>
								{doAllowedDays.map((day, index) => {
									return (
										<FormControlLabel
											required={false}
											key={`debit-order-allowed-day-${index}`}
											control={<Radio required value={day} />}
											label={day}
											value={day}
										/>
									);
								})}
							</RadioGroup>
						</FormControl>
					</FormGrid>
					<FormGrid size={{ xs: 12 }}>
						<FormControl>
							<FormLabel htmlFor="type_of_account" required>
								Type of account
							</FormLabel>
							<RadioGroup
								row
								defaultValue={bank_account_type}
								name="type_of_account"
								onChange={(e) => setBankAccountType(Number(e.target.value))}
							>
								{bankAccountTypes.map((type, index) => {
									return (
										<FormControlLabel
											key={`bank-account-type-${index}`}
											control={<Radio required value={type.id} />}
											label={type.name}
											value={type.id}
										/>
									);
								})}
							</RadioGroup>
						</FormControl>
					</FormGrid>
					<FormGrid size={{ xs: 12 }} sx={{ gap: 2 }}>
						<Divider sx={{ marginTop: 3 }} textAlign="left">
							<Typography variant="h5">Terms and Conditions</Typography>
						</Divider>
						<Typography>
							I/We hereby authorise you to issue and deliver payment
							instructions to your banker for collection against my/our
							abovementioned account at my/our abovementioned bank on condition
							that the sum of such payment instructions will never exceed my/our
							obligations as agreed to in the Agreement.
						</Typography>
						<Typography>
							I/We understand that the withdrawals hereby authorised will be
							processed through a computerised system provided by the South
							African Banks and I also understand that details of each
							withdrawal will be printed on my bank statement. Receipt of this
							instruction by you shall be regarded as receipt thereof by my/our
							bank (whichever it is or will be). If however, the date of the
							payment instruction falls on a non-processing day (Sunday or
							public holiday) I agree that the payment instruction may be
							debited against my account on the following business day.
						</Typography>
						<Box>
							<Typography variant="overline">Mandate</Typography>
							<Typography>
								I/we acknowledge that all payment instructions issued by you
								shall be treated by my/our abovementioned bank as if the
								instructions had been issued by me/us personally.
							</Typography>
						</Box>
						<Box>
							<Typography variant="overline">Cancellation</Typography>
							<Typography>
								I/we agree that although this authority and mandate may be
								cancelled by me/us, such cancellation will not cancel the
								Agreement. I/we also understand that I/we cannot reclaim
								amounts, which have been withdrawn from my/our account (paid) in
								terms of this authority and mandate if such amounts were legally
								owing to you. Written notice of cancellation must be revived no
								later than one calendar month prior to cancellation date.
							</Typography>
						</Box>
						<Box>
							<Typography variant="overline">Assignment</Typography>
							<Typography>
								I/We acknowledge that this authority may be ceded or assigned to
								a third party if the Agreement is also ceded or assigned to that
								third party.
							</Typography>
						</Box>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 6 }}>
						<FormLabel htmlFor="applicant_signed_at" required>
							Signed at
						</FormLabel>
						<OutlinedInput
							id="applicant_signed_at"
							autoComplete="city"
							placeholder="Randburg"
							required
							size="small"
							onChange={(e) => setSignedAt(String(e.target.value))}
						/>
					</FormGrid>
				</Grid>
			</Box>
		</Stack>
	);
}
