import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import { SubscriptionPackage } from "../types/subscriptions.type";
import axios from "axios";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  padding: "0 0 0 8px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    p: {
      display: "flex",
      alignItems: "center",
      lineHeight: "1em",
    },
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  paddingLeft: theme.spacing(5),
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(2.5),
  },
}));

export const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

type PaymentFormProps = {
  isParent: boolean;
  parentIsJoining: boolean;
  setData: (
    packageId: number,
    subId: number,
    minDependants: number,
    subName: string,
    subCost: number
  ) => void;
};

export default function Subscriptions({
  setData,
  isParent,
  parentIsJoining,
  ...props
}: PaymentFormProps) {
  const [packages, setPackages] = React.useState<SubscriptionPackage[]>([]);
  const [expanded, setExpanded] = React.useState<number | false>(1);
  const [minDependants, setMinDependants] = React.useState<number>(0);
  const [subscription, setSubscription] = React.useState<number>(0);

  React.useEffect(() => {
    async function getData() {
      const { data } = await axios.get("subscription-packages");
      setPackages(data);
    }
    getData();
    setMinDependants(getMinDependants());
    setData(
      Number(expanded),
      subscription,
      minDependants,
      getSubName(),
      getSubCost()
    );
  }, [expanded, subscription, minDependants]);

  const getSubName = () => {
    if (expanded && packages.length > 0) {
      const item = packages.filter((obj) => expanded === obj.id);
      return item && item.length > 0 ? item[0].name : "";
    }

    return "";
  };

  const getSubCost = () => {
    if (expanded && packages.length > 0 && subscription) {
      const item = packages.filter((obj) => expanded === obj.id);

      if (
        item &&
        item.length > 0 &&
        item[0] &&
        item[0].subscriptions &&
        item[0].subscriptions.length > 0
      ) {
        const sub = item[0].subscriptions.filter(
          (subObj) => subObj.id === subscription
        );

        if (sub && sub.length > 0) {
          const discount =
            sub[0].discount_percentage > 0
              ? (100 - sub[0].discount_percentage) / 100
              : 1;

          return discount * sub[0].cost;
        }
      }
    }

    return 0;
  };

  const getMinDependants = (): number => {
    const selectedPackage = packages.filter((item) => item.id === expanded);

    if (selectedPackage.length > 0) {
      if (
        isParent &&
        selectedPackage[0].minimum_dependants_required_for_kids > 0
      ) {
        return selectedPackage[0].minimum_dependants_required_for_kids;
      }

      if (!isParent && selectedPackage[0].minimum_dependants_required > 0) {
        return selectedPackage[0].minimum_dependants_required;
      }
    }

    return 0;
  };

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const showPackage = (
    adults_only: boolean,
    both_adults_and_children: boolean,
    isParentJoining: boolean = false
  ): boolean => {
    if (adults_only && !isParent) {
      return true;
    }

    if (!adults_only && isParent) {
      return true;
    }

    if (both_adults_and_children) {
      return true;
    }

    if (isParentJoining) {
      return true;
    }

    return false;
  };

  const showSub = (adults_only: boolean): boolean => {
    if (adults_only && !isParent) {
      return true;
    }

    if (!adults_only && isParent) {
      return true;
    }

    return false;
  };

  const getPackages = (isParentJoining: boolean = false) => {
    return (
      <FormControl sx={{ marginBottom: 4 }}>
        {packages.map((item, index) => {
          return (
            showPackage(
              item.adults_only,
              item.both_adults_and_children,
              isParentJoining
            ) && (
              <Accordion
                expanded={expanded === item.id}
                onChange={(e) => {
                  // handleChange(item.id);
                  setExpanded(item.id);
                }}
                key={`package-${index}`}
              >
                <AccordionSummary
                  aria-controls={`panel${index}d-content`}
                  id="panel1d-header"
                >
                  <Typography>
                    <Radio
                      value={item.id}
                      onChange={(e) => setExpanded(Number(item.id))}
                      name="package"
                      checked={expanded === item.id}
                    />
                    {item.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item.extra_info && (
                    <>
                      <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                        <Typography>{item.extra_info}</Typography>
                      </Box>
                      <Divider sx={{ marginBottom: 1 }} />
                    </>
                  )}
                  <FormControl>
                    <RadioGroup
                      defaultValue={subscription}
                      value={subscription}
                      name="subscription"
                      onChange={(e) => setSubscription(Number(e.target.value))}
                    >
                      {item.subscriptions.map((sub, subIndex) => {
                        return (
                          showSub(sub.adults_only) && (
                            <FormControlLabel
                              key={`subscription-${subIndex}`}
                              control={<Radio value={sub.id} />}
                              label={`${sub.name} (R${sub.cost} per month)`}
                              value={sub.id}
                            />
                          )
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            )
          );
        })}
      </FormControl>
    );
  };

  return (
    <FormControl sx={{ marginBottom: 4 }}>
      {getPackages()}
      {/* {parentIsJoining && getPackages(parentIsJoining)} */}
    </FormControl>
  );
}
