import React from 'react';
import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { themeOptions } from './utils/theme';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Checkout from './examples/checkout/Checkout';
import Dashboard from './pages/admin/dashboard';

function App() {
  const mdTheme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Checkout} />
          <Route path="/admin" Component={Dashboard} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
